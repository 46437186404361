import React, { Component } from 'react'
import { Link } from 'gatsby'

import { editorial } from '../../../config/navigation'

import Page from '../../components/Page'
import SEO from '../../components/SEO'
import Schema from '../../components/Schema'
import EditorialArticleLink from '../../components/articles/Listings/EditorialArticleLink'
import EditorialFeatureArticleLink from '../../components/articles/Listings/EditorialFeatureArticleLink'
import Pagination from '../../components/articles/Listings/Pagination'
import Newsletter from '../../components/Newsletter'

const renderArticleLink = (article, i, currentPage, feature) => {
  if (feature === true && currentPage === 1) {
    if (i === 0) {
      return (
        <div key={article.slug} className="col-12">
          <EditorialFeatureArticleLink article={article} />
        </div>
      )
    } else if ([1].includes(i)) {
      return (
        <div key={article.slug} className="col-6 col-lg-8">
          <EditorialArticleLink size={`lg`} article={article} />
        </div>
      )
    }
  }

  return (
    <div key={article.slug} className="col-6 col-lg-4">
      <EditorialArticleLink size={`md`} article={article} />
    </div>
  )
}

export default class ListTemplate extends Component {
  render() {
    const {
      articles,
      totalPages,
      currentPage,
      baseUri,
      feature,
    } = this.props.pageContext

    let seoTitle = 'Advice, opinions, inspiration and fun features from Erudus'

    const breadcrumbs = [
      {
        name: 'Editorial',
        uri: '/editorial',
      },
    ]

    switch (baseUri) {
      case '/editorial/the-food-agenda':
        seoTitle = `Erudus presents The Food Agenda - covering the food industry's hottest topics`
        breadcrumbs.push({
          name: 'The Food Agenda',
          uri: baseUri,
        })
        break
      case '/editorial/recipe-book':
        seoTitle =
          'Erudus Recipe Book, with allergen and nutritional information for every recipe'
        breadcrumbs.push({
          name: 'Recipe Book',
          uri: baseUri,
        })
        break
      case '/editorial/insider-diaries':
        seoTitle =
          'Erudus Insider Diaries - allergy sufferers and Erudus users tell all'
        breadcrumbs.push({
          name: 'Insider Diaries',
          uri: baseUri,
        })
        break
      case '/editorial/erudus-community':
        seoTitle =
          'The Erudus Community, insider features from the whole foodservice industry'
        breadcrumbs.push({
          name: 'The Erudus Community',
          uri: baseUri,
        })
        break
      case '/editorial/the-takeaway':
        seoTitle = `Erudus presents The Takeaway, rounding up the month's best foodservice stories`
        breadcrumbs.push({
          name: 'The Takeaway',
          uri: baseUri,
        })
        break
    }

    return (
      <Page className="p-posts p-posts--editorial has-fingerPrints">
        <SEO title={seoTitle} />
        <Schema breadcrumbs={breadcrumbs} />

        <section id="news" className="c-postsHeader c-postsHeader--editorial">
          <div className="container">
            <div className="c-postsHeader__inner row">
              <div className="c-postsHeader__title col-md-2">
                <h1>Editorial</h1>
              </div>
              <div className="c-postsHeader__text col-md-10">
                Advice, inspiration and fun{' '}
                <strong>features from the Erudus team</strong>.
              </div>
            </div>
            <div className="c-postsHeaderMenuWrapper">
              <div className="form-group pb-5 d-block d-xl-none">
                <select
                  value={baseUri}
                  onChange={(event) => (window.location = event.target.value)}
                  className="form-control"
                >
                  <option value="/editorial">All Editorial</option>
                  {editorial.map((link) => (
                    <option key={`${link.name}_mobile`} value={link.to}>
                      {link.name}
                    </option>
                  ))}
                </select>
              </div>

              <ul className="c-postsHeaderMenu d-none d-xl-flex">
                <li className={baseUri === '/editorial' ? 'is-active' : ''}>
                  <Link to="/editorial" title="All News">
                    All Editorial
                  </Link>
                </li>
                {editorial.map((link) => (
                  <li
                    key={link.name}
                    className={baseUri === link.to ? 'is-active' : ''}
                  >
                    <Link to={link.to} title={link.name}>
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        <section id="latest-editorial" className="c-latestEditorial">
          <div className="container">
            <div className="text-right py-4">
              <Link to="/search" className="c-postReturnLink">
                {/* <img src="/icons/arrow-right.svg" className="c-icon mr-2" /> */}
                <span className="c-postReturnLink__text">Search</span>
              </Link>
            </div>
            <div className="c-latestEditorial__inner pt-2">
              <div className="c-latestEditorial__items row">
                {articles.map((article, i) =>
                  renderArticleLink(article, i, currentPage, feature)
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="c-section bg-white">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                {totalPages > 1 && (
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    baseUri={baseUri}
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <Newsletter />
      </Page>
    )
  }
}
